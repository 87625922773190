@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
#cent {
  font-family: "Sans MS", Verdana, Arial, Helvetica, sans-serif;
  text-align: center;
  width: 100%;
}
label > input[type=file] {
    display: none;
}
.image {
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 21px;
  padding-top: 12px;
}
.bill {
  height: 70px;
  margin: 4px 7px 4px 10px;
  cursor: pointer;
}
#crew {
  height: 140px;
}
#posters2 {
    height: 155px;
    width: 104%;
}
.Rating { 
  font-size: 35px; 
}
.head {
    height: 73px;
    padding: 3px 0px;
    border: groove white;
    background-color: white;
}
.news {
    height: 44px;
    float: left;
    width: 65%;
}
.Icon {
    position: absolute;
    width: 67px;
    height: 67px;
    margin: auto;
    z-index: 3;
    box-shadow: 0 0 0 10px rgb(255 255 255 / 20%), 0 0 25px 2px rgb(0 0 0 / 40%), inset 0 0 0 15px rgb(255 255 155 / 40%);
    border-radius: 999px;
}
.Puls {
    position: relative;
    left: 432px;
    bottom: 50px;
    width: 67px;
    height: 67px;
    margin: auto;
    z-index: 1;
    opacity: 0;
    border: 3px solid rgba(255,255,255,.1);
    -webkit-animation: pulsejg1 4s linear infinite;
    animation: pulsejg1 4s linear infinite;
    border-radius: 999px;
    box-shadow: inset 0px 0px 15px 10px rgb(0 0 0 / 60%);
    box-sizing: border-box;
}
.Puls2 {
    position: relative;
    left: 432px;
    bottom: 116px;
    width: 67px;
    height: 67px;
    margin: auto;
    z-index: 2;
    opacity: 0;
    border: 1px solid rgba(255,255,255,0);
    -webkit-animation: pulsejg2 4s linear infinite;
    animation: pulsejg2 4s linear infinite;
    border-radius: 999px;
    box-shadow: inset 0px 0px 12px 5px rgb(255 255 255 / 80%);
    box-sizing: border-box;
}
.Avat {
    margin: 0 56px;
}

@-webkit-keyframes pulsejg1 {
  0% {
      -webkit-transform: scale(.6);
      opacity: 0;
  }

  50% {
      -webkit-transform: scale(.6);
      opacity: 0;
  }

  60% {
      -webkit-transform: scale(.9);
      opacity: .2;
  }

  70% {
      -webkit-transform: scale(1.1);
      opacity: .35;
  }

  80% {
      -webkit-transform: scale(1.25);
      opacity: .2;
  }

  100% {
      -webkit-transform: scale(1.4);
      opacity: 0;
  }
}

@-moz-keyframes pulsejg1 {
  0% {
      -moz-transform: scale(.6);
      opacity: 0;
  }

  50% {
      -moz-transform: scale(.6);
      opacity: 0;
  }

  60% {
      -moz-transform: scale(.9);
      opacity: .2;
  }

  70% {
      -moz-transform: scale(1.1);
      opacity: .35;
  }

  80% {
      -moz-transform: scale(1.25);
      opacity: .2;
  }

  100% {
      -moz-transform: scale(1.4);
      opacity: 0;
  }
}

@keyframes pulsejg1 {
  0% {
      transform: scale(.6);
      opacity: 0;
  }

  50% {
      transform: scale(.6);
      opacity: 0;
  }

  60% {
      transform: scale(.9);
      opacity: .1;
  }

  70% {
      transform: scale(1.1);
      opacity: .25;
  }

  80% {
      transform: scale(1.25);
      opacity: .1;
  }

  100% {
      transform: scale(1.4);
      opacity: 0;
  }
}

@-webkit-keyframes pulsejg2 {
  0% {
      -webkit-transform: scale(.6);
      opacity: 0;
  }

  40% {
      -webkit-transform: scale(.8);
      opacity: .05;
  }

  50% {
      -webkit-transform: scale(1);
      opacity: .1;
  }

  60% {
      -webkit-transform: scale(1.1);
      opacity: .3;
  }

  80% {
      -webkit-transform: scale(1.2);
      opacity: .1;
  }

  100% {
      -webkit-transform: scale(1.3);
      opacity: 0;
  }
}

@-moz-keyframes pulsejg2 {
  0% {
      -moz-transform: scale(.6);
      opacity: 0;
  }

  40% {
      -moz-transform: scale(.8);
      opacity: .05;
  }

  50% {
      -moz-transform: scale(1);
      opacity: .1;
  }

  60% {
      -moz-transform: scale(1.1);
      opacity: .3;
  }

  80% {
      -moz-transform: scale(1.2);
      opacity: .1;
  }

  100% {
      -moz-transform: scale(1.3);
      opacity: 0;
  }
}

@keyframes pulsejg2 {
  0% {
      transform: scale(.6);
      opacity: 0;
  }

  40% {
      transform: scale(.8);
      opacity: .05;
  }

  50% {
      transform: scale(1);
      opacity: .1;
  }

  60% {
      transform: scale(1.1);
      opacity: .3;
  }

  80% {
      transform: scale(1.2);
      opacity: .1;
  }

  100% {
      transform: scale(1.3);
      opacity: 0;
  }
}